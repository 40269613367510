<template>
  <base-section id="info-alt" space="56" style="padding-top: 0px !important">
    <base-section-heading :title="$t('carousel.title')"> </base-section-heading>
    <div
      style="
        padding-bottom: 16px;
        max-width: 840px;
        text-align: center;
        margin: auto;
        display: none;
        font-weight: 800;
        font-size: 1rem;
      "
    >
      {{ $t("carousel.subTitle") }}
    </div>
    <div class="perksContainer">
      <v-row v-for="(item, i) in rows" :key="i" style="padding: 16px">
        <v-col
          :lg="item.pictureSrc ? '12' : '12'"
          :xl="item.pictureSrc ? '12' : '12'"
          md="12"
          sm="12"
          xs="12"
          style="margin: auto; padding: 4px"
        >
          <div class="containerHero">
            <div class="itemHero">
              <div
                style="
                  padding-bottom: 16px;
                  max-width: 840px;
                  text-align: center;
                  margin: auto;
                  font-weight: 600;
                  font-size: 20px;
                "
                class="content"
              >
                {{ $t(item.textSection.headerCode) }}
              </div>
              <div
                v-if="item.textSection.description"
                class="description"
                style="
                  padding-bottom: 16px;
                  max-width: 840px;
                  text-align: center;
                  margin: auto;
                  font-weight: 600;
                "
              >
                {{ item.textSection.description }}
              </div>
              <div class="content" style="padding-bottom: 32px">
                <v-row justify="center">
                  <v-col
                    style="color: rgba(255, 255, 255, 0.85); margin: 8px"
                    :key="t"
                    v-for="(card, t) in item.textSection.features"
                    cols="12"
                    :md="item.pictureSrc ? '2' : '2'"
                    :sm="item.pictureSrc ? '2' : '2'"
                  >
                    <v-container class="pa-0">
                      <v-row justify="center" no-gutters>
                        <v-col
                          class="text-center mb-4"
                          cols="12"
                          justify="center"
                          v-if="card.icon"
                        >
                          <base-icon
                            style="
                              overflow: hidden;
                              height: 6rem;
                              width: 6rem;
                              border-radius: 50%;
                              margin: auto;
                              text-align: center;
                              box-shadow: 0 3px 20px 0 rgb(0 0 0 / 16%);
                              background-color: #fff;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            {{ card.icon }}
                          </base-icon>
                        </v-col>

                        <v-col
                          v-if="card.title"
                          cols="12"
                          style="text-align: center; color: rgba(0, 0, 0, 0.54)"
                        >
                          <span
                            style="
                              font-weight: bold;
                              font-size: 16px !important;
                              text-align: center !important;
                            "
                          >
                            {{ $t(card.titleCode) }}
                          </span>
                          <div v-if="card.text" v-text="text" space="0" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          lg="12"
          xl="12"
          md="12"
          sm="12"
          xs="12"
          style="margin: auto; padding: 4px"
          v-if="item.pictureSrc"
        >
          <v-row justify="center">
            <v-img
              cover
              :src="item.pictureSrc"
              :key="i + 'app-pic'"
              style="width: 100%; max-width: 840px; border: 2px solid rgba(0, 0, 0, 0.15);"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
  </base-section>
</template>

<style scoped>
.triangle-bottomleft {
  width: 0;
  height: 0;
  border-bottom: 100px solid white;
  border-right: 100px solid transparent;
}
</style>

<script>
import "@/styles/devices.min.css";
export default {
  name: "SectionCarousel",
  computed: {
    maxScreenHeigth() {
      return this.maxScreenWidth / 1.6;
    },
    maxScreenWidth() {
      if (this.screenWidth > 1048) {
        // 88 padding on screen
        return 960;
      } else {
        return 1048 / (1048 / this.screenWidth);
      }
    },
    screenWidth: {
      get() {
        return this.$store.state.screenWidth;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenWidth", newValue);
      },
    },
    screenCalcTriangleBorderBottom() {
      return (100 / 1680) * this.screenWidth; // 1050px for reference
    },
  },
  data() {
    return {
      rows: [
        {
          textSection: {
            header: "Orders & bookings",
            headerCode: "carousel.orders",
            description: "",
            features: [
              {
                icon: "mdi-truck-delivery-outline",
                title: "Delivery planning",
                titleCode: "carousel.orders1"
              },
              {
                icon: "mdi-receipt-text-outline",
                title: "Invoicing",
                titleCode: "carousel.orders2"
              },
              {
                icon: "mdi-ballot-outline",
                title: "Rentables, products, services, bundles",
                titleCode: "carousel.orders3"
              },
              {
                icon: "mdi-sitemap-outline",
                title: "Custom order workflows",
                titleCode: "carousel.orders4"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-orders.png",
        },
        {
          textSection: {
            header: "Tentifly assistant",
            headerCode: "carousel.assistant",
            description: "",
            features: [
              {
                icon: "mdi-message-badge-outline",
                title: "Customer notifications",
                titleCode: "carousel.assistant1"
              },
              {
                icon: "mdi-bell-outline",
                title: "Employee notifications",
                titleCode: "carousel.assistant2"
              },
              {
                icon: "mdi-lightbulb-auto-outline",
                titleCode: "carousel.assistant3",
                title: "AI suggestions"
              },
              {
                icon: "mdi-typewriter",
                title: "Translations & descriptions",
                titleCode: "carousel.assistant4"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-product.png",
        },
        {
          textSection: {
            header: "Customers",
            headerCode: "carousel.customer",
            description: "",
            features: [
              {
                icon: "mdi-clipboard-text-clock-outline",
                title: "Order & communication history",
                titleCode: "carousel.customer1"
              },

              {
                icon: "mdi-account-heart-outline",
                title: "Automatic customer retention",
                titleCode: "carousel.customer2"
              },
              {
                icon: "mdi-account-multiple-check-outline",
                title: "Billing groups",
                titleCode: "carousel.customer3"
              },
              {
                icon: "mdi-tag-multiple-outline",
                title: "Custom tagging",
                titleCode: "carousel.customer4"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-calendar.png",
        },
        {
          textSection: {
            header: "Inventory",
            headerCode: "carousel.inventory",
            description: "",
            features: [
              {
                icon: "mdi-counter",
                title: "Stocktaking & -keeping",
                titleCode: "carousel.inventory1"
              },
              {
                icon: "mdi-car-key",
                title: "Rentables room planning",
                titleCode: "carousel.inventory2"
              },
              {
                icon: "mdi-gift-outline",
                title: "Sale bundling",
                titleCode: "carousel.inventory3"
              },
              {
                icon: "mdi-account-details",
                title: "Unlimited number of clients",
                titleCode: "carousel.inventory4"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-room.png",
        },
        {
          textSection: {
            header: "Sale forms",
            headerCode: "carousel.forms",
            description: "",
            features: [
              {
                icon: "mdi-list-box-outline",
                title: "Unlimited sale forms",
                titleCode: "carousel.forms1"
              },
              {
                icon: "mdi-go-kart-track",
                title: "Custom form steps",
                titleCode: "carousel.forms2"
              },
              {
                icon: "mdi-floor-plan",
                title: "Room graphical layouts",
                titleCode: "carousel.forms3"
              },
              {
                icon: "mdi-form-textbox",
                title: "Custom data fields",
                titleCode: "carousel.forms4"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app_predictions.png",
        },
        {
          textSection: {
            header: "Dashboard",
            headerCode: "carousel.dashboard",
            description: "",
            features: [
              {
                icon: "mdi-file-chart-outline",
                title: "Current statistics",
                titleCode: "carousel.dashboard1"
              },
              {
                icon: "mdi-axis-arrow-info",
                title: "Reminders & suggestions",
                titleCode: "carousel.dashboard2"
              },
              {
                icon: "mdi-chart-multiple",
                title: "Business predictions",
                titleCode: "carousel.dashboard3"
              },
              {
                icon: "mdi-view-dashboard-outline",
                title: "Personal dashboard",
                titleCode: "carousel.dashboard4"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-form.png",
        },
        {
          textSection: {
            header: "Employees",
            headerCode: "carousel.employees",
            description: "",
            features: [
              {
                icon: "mdi-account-hard-hat-outline",
                title: "Unlimited employees",
                titleCode: "carousel.employees1"
              },
              {
                icon: "mdi-lock-outline",
                title: "Detailed access management",
                titleCode: "carousel.employees2"
              },
              {
                icon: "mdi-briefcase-outline",
                title: "Worksheet planning",
                titleCode: "carousel.employees3"
              },
              {
                icon: "mdi-bike-fast",
                title: "Statistics and motivation system",
                titleCode: "carousel.employees4"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-dashboard.png",
        },
        {
          textSection: {
            header: "Unique extras",
            headerCode: "carousel.extras",
            description: "",
            features: [
              {
                icon: "mdi-store-outline",
                title: "No-setup e-shop page",
                titleCode: "carousel.extras1"
              },
              {
                icon: "mdi-toy-brick-outline",
                title: "Webpage Tentifly plugin",
                titleCode: "carousel.extras1"
              },
              {
                icon: "mdi-shopping-outline",
                title: "Tentifly marketplace spot",
                titleCode: "carousel.extras1"
              },
              {
                icon: "mdi-note-text-outline",
                title: "Pre-made sale form templates",
                titleCode: "carousel.extras1"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/online_shops.png",
        },
        {
          textSection: {
            header: "Marketing",
            headerCode: "carousel.marketing",
            description: "",
            features: [
              {
                icon: "mdi-text-box-edit-outline",
                title: "Auto translations & descriptions",
                titleCode: "carousel.marketing1"
              },
              {
                icon: "mdi-image-marker-outline",
                title: "Premade marketing templates",
                titleCode: "carousel.marketing2"
              },
              {
                icon: "mdi-qrcode-scan",
                title: "Easy sale form sharing",
                titleCode: "carousel.marketing3"
              },
              {
                icon: "mdi-radar",
                title: "Marketing insights",
                titleCode: "carousel.marketing4"
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/ongline_catalog.png",
        },
      ],
    };
  },
};
</script>
